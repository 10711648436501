<template>
  <div>
    <location />
    <div class="page wrap-1200 rule">
      <div class="rule-overview">
        (주)엑스골프(이하 "회사"라 한다)은 고객의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호" 등에
        관한 법률을 준수하고 있습니다. 회사는 개인정보 처리방침을 통하여 이용자로부터 제공받은 개인정보를 어떠한 용도와
        방식으로 이용하고 있으며, 개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다. 개인정보 처리방침은 관련
        법률 및 지침의 변경과 내부 운영 방침의 변경에 따라 변경될 수 있으며, 회사는 개인정보 처리방침을 개정하는 경우
        사이트 공지사항 또는 서면·이메일·전화·SMS 등의 방법으로 개별 공지할 것입니다.
      </div>

      <div class="rule-list">
        <h5>1. 개인정보의 수집 및 이용 목적</h5>
        <ul>
          <li>
            당사는 서비스 제공을 위하여 필요한 최소한의 범위 내에서 다음 각 항목과 같은 목적으로 개인정보를 수집하고 있습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 서비스 이용에 따른 본인 식별, 부정 이용 방지
              </li>
              <li>
                <span>②</span> 각종 온라인 이벤트 당첨 확인 및 공지
              </li>
              <li>
                <span>③</span> 경품 발송을 위한 본인 식별 및 배송지 확인
              </li>
              <li>
                <span>④</span> 스크린골프대회 참가를 위한 본인 식별
              </li>
              <li>
                <span>⑤</span> 각종 라운딩 관련 통계 및 분석 데이터를 제공하기 위하여 사용
              </li>
              <li>
                <span>⑥</span> 새로운 서비스를 기획하기 위한 통계 등을 위해 사용
              </li>
              <li>
                <span>⑦</span> 유료 서비스 이용 시 정확한 과금과 결제 등을 위해 사용
              </li>
              <li>
                <span>⑧</span> 가입 의사 확인, 고지사항 전달, 불만 처리 등 의사소통 경로의 확보
              </li>                                                       
            </ul>            
          </li>
        </ul>

        <h5>2. 수집하는 개인정보</h5>
        <ul>
          <li>
            <ul class="sub-item">
              <li>
                <span>①</span> 필수항목 : 성명, 성별, 휴대폰 번호, 아이디, 닉네임, 비밀번호, 비밀번호확인, 이메일, 생년월일, 주소
              </li>
              <li>
                <span>②</span> 선택항목 : 개인 티 높이 설정
              </li>
              <li>
                <span>③</span> 서비스 이용과정이나 처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
                <p class="add">- IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 결제 기록 등</p>
              </li>
            </ul>            
          </li>
        </ul>

        <h5>3. 개인정보 보유 및 이용기간</h5>
        <ul>
          <li>
            회사는 이용자의 개인정보 수집 및 이용 동의일부터 서비스를 제공하는 기간 동안 이용자의 개인정보를 보유합니다. 
            회사는 회원이 탈퇴하거나 요청하는 경우 혹은 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 
            단, 상법 등 관계법령에 규정에 의하여 보존할 필요가 있는 경우 회사는 다음과 같이 관련 정보를 일정 기간 보유합니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 계약 또는 청약철회 등에 관한 기록 : 5년
              </li>
              <li>
                <span>②</span> 대금 결제 및 재화 등의 공급에 관한 기록 : 5년
              </li>
              <li>
                <span>③</span> 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
              </li>
              <li>
                <span>④</span> 불량 이용 및 이용 제한에 관한 기록 : 1년
              </li>
              <li>
                <span>⑤</span> 개인위치정보에 관한 기록 : 1년
              </li>
              <li>
                <span>⑥</span> 표시, 광고에 관한 기록 : 6개월
              </li>
              <li>
                <span>⑦</span> 본인 확인에 관한 기록 : 6개월
              </li>
              <li>
                <span>⑧</span> 웹사이트 방문 기록 : 3개월
              </li>
            </ul>            
          </li>
        </ul>              

        <h5>4. 개인정보 보호책임자</h5>
        <ul>
          <li>
            당사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만사항 및 문의를 처리하기 위하여 아래와 같이
            개인정보보호책임자를 지정하고 있습니다. 회사는 이용자들의 개인정보 관련 문의사항에 대해 신속하고
            충분한 답변을 드리기 위해 최선을 다해 노력하겠습니다.
            <ul class="sub-item my-4">
              <li>
                <h6>개인정보 보호관리 책임자</h6>
                기술연구소 정순욱 | 1544-0779
                <h6>개인정보 보호관리 담당자</h6>
                기술연구소 나보윤 | 1544-0779
              </li>
            </ul>
          </li>
          <li>
            기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우 아래 기관으로 문의하시기 바랍니다.
            <ul class="type-circle my-2">
              <li>경찰청 사이버범죄 신고시스템 (국번없이) 182</li>
              <li>대검찰청 과학수사부 사이버수사과 (국번없이) 1301</li>
              <li>개인정보 침해신고센터 (국번없이) 118</li>
              <li>개인정보 분쟁조정위원회 1833-6972</li>
            </ul>
          </li>
        </ul>

        <h5>5. 고지의 의무</h5>
        <ul>
          <li>
            현 개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지를 통해 고지할 것입니다.
          </li>
          <li>
            개인정보 처리방침 공고일자 : 2022.07.26<br>
            개인정보 처리방침 시행일자 : 2022.07.26
          </li>          
        </ul>                   

      </div><!-- // rule-list -->
    </div>
  </div>
</template>

<script>
import location from '@/components/Location'

export default {
  name: 'Policy',
  components: {
    location
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>