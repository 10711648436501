<template>
  <div>
    <location />
    <div class="page wrap-1200 rule">
      <div class="rule-overview">
        (주)엑스골프 서비스의 이용과 관련하여 필요한 사항을 규정합니다.
        엑스골프 계정을 만들거나, 서비스 다운로드 및 서비스를 사용하는 것은 본 약관에 동의한 것과 같으니
        서비스를 이용하기 전에 반드시 약관을 자세히 읽어보세요.
      </div>

      <div class="rule-list">
        <h5>제1조 목적</h5>
        <ul>
          <li>
            이 약관은 (주)엑스골프(이하 "회사"라 한다)에서 운영하는 인터넷 관련 서비스(이하 "서비스"라 한다)를
            이용함에 있어 회사와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.
          </li>
        </ul>

        <h5>제2조 정의</h5>
        <ol class="list-group-numbered">
          <li>
            "사이트"란 회사가 재화 또는 용역(이하 "재화 등"이라 한다)을 이용자에게 제공하기 위하여 컴퓨터,
            모바일 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장 또는 회사가
            운영하는 웹사이트, 모바일 웹, 앱 등의 서비스를 제공하는 모든 매체를 통칭하며,
            회원 계정(아이디 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는 사이트를 말하며,
            아울러 사이트를 운영하는 사업자의 의미로도 사용합니다.
          </li>
          <li>
            "서비스"란 이용 고객 또는 회원이 PC, 이동전화, 휴대용 단말기 등 각종 유무선 기기 또는 프로그램을 통하여
            이용할 수 있도록 회사가 제공하는 모든 서비스를 말합니다.
          </li>
          <li>
            "이용자"란 "사이트"에 접속하여 이 약관에 따라 "사이트"가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
          </li>
          <li>
            "회원"이란 본 약관 및 개인정보처리방침에 동의하고, "이용 계약"을 체결하여 "사이트"가 제공하는 서비스를
            이용하는 고객을 말합니다.
          </li>
          <li>
            "이용 계약"이란 약관을 포함하여 서비스 이용과 관련하여 회사와 회원 간에 체결하는 모든 계약을 말합니다.
          </li>
          <li>
            "아이디(ID)"란 “회원”의 식별과 사이트에서 제공하는 서비스를 이용하고,
            회사가 회원을 구분, 식별할 수 있도록, 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
          </li>
          <li>
            "비밀번호" 란 회원이 부여받은 "아이디(ID)"와 동일한 회원임을 확인하고 비밀 보호를 위해
            회원 자신이 설정하여 회사에 등록한 문자 또는 숫자의 조합을 의미합니다.
          </li>
          <li>
            "게시물"이란 회사, 회원이 서비스 내에 등록한 글, 사진, 각종 파일, 링크 등을 말합니다.
          </li>
          <li>
            이 약관에서 사용하는 용어의 정의는 이 조항에서 정하는 것을 제외하고는 관계 법령 및 서비스별
            안내에서 정하는 바에 의합니다.
          </li>                                                                    
        </ol>

        <h5>제3조 약관의 명시와 개정</h5>
        <ol class="list-group-numbered">
          <li>
            이 약관은 "사이트"에 그 내용을 이용자가 알 수 있도록 게시함으로써 효력을 발생하며 "회사"는 약관의 규제 등에
            관한 법률, 전자거래 기본법, 전자서명법, 정보통신망 이용 촉진 등에 관한 법률,
            소비자 보호법 등 관련 법을 위배하지 않는 범위에서 개정할 수 있습니다.
          </li>
          <li>
            회사는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 상호, 소재지, 대표자의 성명,
            사업자등록번호, 연락처 등과 함께 게시합니다.
          </li>
          <li>
            회사가 약관을 변경하는 경우 적용일자, 개정 사유를 명시하여 현행 약관과 함께
            "사이트"에 그 적용일자 7일 이전부터 적용일자 전일까지 약관 변경 사실을 공지하면, 약관 변경의 효력이 발생합니다.
          </li>
          <li>
            회원은 정기적으로 "사이트"를 방문하여 약관의 변경사항을 확인하여야 합니다.
            변경된 약관에 대한 정보를 알지 못해 발생하는 회원의 피해는 회사에서 책임지지 않습니다.
          </li>
          <li>
            회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 회원 탈퇴(이용 계약 해지) 하거나
            약관 변경에 동의하지 아니한다는 의사를 표명할 수 있습니다. 다만, 제3항의 공지에도 불구하고
            변경된 약관의 효력 발생일 이후에도 회원 탈퇴를 하지 않거나 서비스를 계속 이용하는 회원은
            약관 변경에 동의한 것으로 간주합니다.
          </li>                    
        </ol>

        <h5>제4조 약관 외 준칙</h5>
        <ul>
          <li>
            본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 회사가 정한 서비스의 개별 이용약관,
            운영정책 및 규칙 등(이하 "세부지침")의 규정에 따릅니다. 또한 본 약관과 세부지침의 내용이
            충돌할 경우 세부지침에 따릅니다.
          </li>
        </ul>

        <h5>제5조 이용계약의 성립</h5>
        <ul>
          <li>
            이용계약은 “회원”이 되고자 본 약관에 동의한 후 회사가 정한 양식에 따라 회원정보를 기입하여
            회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
          </li>
        </ul>        

        <h5>제6조 서비스 이용 신청</h5>
        <ol class="list-group-numbered">
          <li>
            회원으로 가입하고자 하는 이용자는 이용계약의 내용에 동의하고 회사에서 요청하는
            이용자 ID, 비밀번호, 이름, 연락처 등의 제반 정보(이하 ‘제반 정보'라 한다)를 제공하여야 합니다.
          </li>
          <li>
            모든 회원은 반드시 회원 본인의 정보를 제공하여야만 서비스를 이용할 수 있고, 타인의 정보를
            도용하거나 허위의 정보를 등록하는 등 본인의 진정한 정보를 등록하지 않은 회원은 서비스 이용과 관련하여
            아무런 권리를 주장할 수 없으며, 관계 법령에 따라 처벌받을 수 있습니다.
          </li>
          <li>
            회사는 회원이 등록한 제반 정보의 진정 여부를 확인할 수 있고, 회원은 회사의 확인 조치에 대하여
            적극 협력하여야 합니다. 만약 회원이 회사의 제반 정보 확인 조치에 협력하지 아니하는 경우
            회사는 회원이 등록한 정보가 진정하지 아니한 것으로 간주할 수 있습니다.
          </li>
          <li>
            회사는 회원에 대하여 등급별로 구분하여 이용 시간, 이용 회수, 서비스 메뉴 등을 세분하여 이용에
            차등을 둘 수 있습니다.
          </li>
          <li>
            회사 또는 회사가 지정하는 자는 회원으로 가입하고자 하는 이용자로부터 제반 정보를
            서면으로 제공받아 인터넷상의 회원가입 절차상의 업무를 위임받아 대신 진행할 수 있습니다.
          </li>                    
        </ol>

        <h5>제7조 이용 신청의 승낙과 제한</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 제5조, 제6조의 규정에 의한 이용 신청에 대하여 원칙적으로 접수 순서에 따라 서비스 이용을 승낙합니다.
          </li>
          <li>
            회사는 다음 각호에 해당하는 이용 신청에 대하여는 서비스 제공을 거부하거나 제한할 수 있고,
            이 사유가 해소될 때까지 이용 신청 승낙을 유보할 수 있습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 이용자 등록 시 타인의 명의를 도용하여 허위로 신청하는 경우
              </li>
              <li>
                <span>②</span> 회사가 제시하는 이용자 등록 사항을 누락하거나 오기 또는 허위 정보를 기재하여 신청하는 경우
              </li>
              <li>
                <span>③</span> 관계 법령 위반, 사회의 안녕·질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우
              </li>
              <li>
                <span>④</span> 악성 프로그램 및 버그를 이용하거나 시스템 취약점을 악용하는 등 부정한 방법을 서비스에 사용한 경우
              </li>
              <li>
                <span>⑤</span> 이용자의 귀책사유로 인하여 이용계약이 해지된 적이 있는 이용자가 신청하는 경우
              </li>
              <li>
                <span>⑥</span> 기타 규정한 제반 사항을 위반하여 신청하거나 회사가 정한 이용 신청 요건이 만족되지 않았을 경우
              </li>
              <li>
                <span>⑦</span> 기술상 지장이 있거나 설비의 여유가 없는 경우 또는 기타 회사의 사정상 이용 승낙이 곤란한 경우
              </li>
              <li>
                <span>⑧</span> 회사는 회원 가입 절차 완료 이후 제2항 각 호에 해당하는 행위를 하였을 경우
                사전 통지 없이 자격을 제한/정지하거나 이용 승낙을 철회할 수 있습니다.
              </li>                                                   
            </ul>            
          </li>
        </ol>

        <h5>제8조 개인정보의 보호 및 사용</h5>
        <ol class="list-group-numbered">
          <li>
            "회사"는 "정보통신망법 및 개인정보보호법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다.
            개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 개인정보처리방침이 적용됩니다. 
          </li>
          <li>
            "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.
          </li>
          <li>
            회원은 비밀번호 등이 타인에게 노출되지 않도록 철저히 관리해야 하고,
            회사는 회원의 귀책사유로 인해 노출된 개인 정보에 대하여는 책임지지 않습니다.
          </li>                  
          <li>
            회사는 다음 각 호에 해당하는 경우 법령이 제한하는 범위 내에서 제3자에게 회원의 개인 정보를 제공할 수 있습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 수사기관이나 기타 정부기관으로부터 정보제공을 요청받은 경우
              </li>
              <li>
                <span>②</span> 회원의 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호 업무를 위해 필요한 경우
              </li>
              <li>
                <span>③</span> 기타 법령에 의해 요구되는 경우
              </li>
            </ul>            
          </li>
        </ol>

        <h5>제9조 이용자 ID 부여 및 변경 등</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 회원에 대하여 약관에 정하는 바에 따라 이용자 ID를 부여합니다.
          </li>
          <li>
            이용자 ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자
            하는 경우에는 해당 이용자 ID를 해지하고, 재차 서비스 이용 신청을 하여야 합니다.
          </li>
          <li>
            홈페이지의 이용자 ID는 회원 본인의 동의하에 회사 또는 자회사가 운영하는 다른 사이트의 회원 ID와 연결될 수 있습니다.
          </li>                  
          <li>
            다음 각 호에 해당하는 경우 이용자 ID는 회원의 요청으로 변경되거나 회사의 직권으로 이용이 정지될 수 있습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 이용자 ID가 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가 우려되는 경우
              </li>
              <li>
                <span>②</span> 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
              </li>
              <li>
                <span>③</span> 회사, 회사의 서비스 또는 서비스 운영자 등의 명칭과 동일하거나 오인 등의 우려가 있는 경우
              </li>
              <li>
                <span>④</span> 기타 합리적인 사유가 있는 경우
              </li>              
            </ul>           
          </li>
          <li>
            "아이디(ID)"와 "비밀번호"에 관한 관리 책임은 전적으로 "회원"에게 있고, 이를 제3자가 이용하도록 하여서는 안되며,
            관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.
          </li>
          <li>
            "회원"은 "아이디(ID)" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를
            즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.
          </li>
          <li>
            제6항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지 않거나, 통지한 경우에도 "회사"의 안내에
            따르지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.
          </li>
          <li>
            기타 회원 개인 정보 관리 및 변경 등에 관한 사항은 서비스별 안내에 정하는 바에 의합니다.
          </li>                                        
        </ol>                   

        <h5>제10조 회사의 의무</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 관계 법령 및 이 약관이 금지하거나 공공의 질서와 선량한 풍속에 반하는 행위를
            하지 않으며 이 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공하기 위해 노력합니다.
          </li>
          <li>
            회사는 회원의 이용 신청을 승낙한 때부터 특별한 사정이 없는 한 서비스를 개시합니다.
            단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.
          </li>
          <li>
            회사는 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는
            부득이한 사유가 없는 한 지체 없이 설비를 수리 또는 복구합니다.
          </li>
          <li>
            회사는 회원으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정되는 경우
            적절한 절차를 거쳐 즉시 처리합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게
            그 사유와 처리 일정을 통지합니다.
          </li>                              
        </ol>

        <h5>제11조 회원의 의무</h5>
        <ol class="list-group-numbered">
          <li>
            회원은 회원가입 신청, 회원정보 변경 시 모든 사항을 사실에 근거하여 본인의
            진정한 정보로 작성하여야 합니다.
          </li>
          <li>
            회원이 허위 또는 타인의 정보를 등록할 경우 회원은 이용계약과 이용계약에 부수하여
            발생되는 일체의 권리를 주장할 수 없습니다.
          </li>
          <li>
            회원은 관계 법령, 본 약관의 규정, 개별약관, 이용안내 및 서비스상에 공지사항,
            회사가 통지하는 사항 등을 준수하여야 합니다.
          </li>
          <li>
            회원은 회사의 업무에 방해되는 행위, 회사의 명예를 손상시키는 행위,
            타인에게 피해를 주는 행위를 하여서는 아니 됩니다.
          </li>
          <li>
            회원은 제반 정보가 변경되는 경우 회원정보 변경 등의 방식으로 즉시 회사에 알려야 하고,
            만약 회원이 이러한 고지의무를 게을리하여 발생되는 손해에 대하여는 회사는 책임을 부담하지 아니합니다.
          </li>
          <li>
            회원은 회사에서 공식적으로 인정한 경우를 제외하고는 영리를 목적으로 서비스를 이용하여 영업 활동을
            할 수 없으며 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법 배포 등을 할 수 없습니다.
            이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계 기관에 의한 구속 등 법적 조치 등에 관해서는
            회사가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.
          </li> 
          <li>
            회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를
            제3자에게 양도, 증여, 담보 제공하는 등의 일체의 처분행위를 할 수 없습니다.
          </li>
          <li>
            회원은 회사 및 제3자의 지적재산권을 포함한 제반 권리를 침해하거나 제19조 각 호에
            해당하는 행위를 해서는 안됩니다.
          </li>                                                  
        </ol>

        <h5>제12조 서비스의 제공 및 변경</h5>
        <ol class="list-group-numbered">
          <li>
            사이트 이용은 특별한 사정이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
          </li>
          <li>
            회사는 서비스를 일정 범위로 분할하여 각 범위별로 이용 가능한 시간을 별도로 정할 수 있습니다.
          </li>
          <li>
            회사의 업무상/기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영상의 목적으로 회사가
            정한 기간에 서비스가 일시 중지될 수 있습니다.
          </li>
          <li>
            회사는 업무상/기술상/운영상 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
          </li>
          <li>
            회사는 서비스 개편 등 운영상 필요하다고 판단되는 경우 회원에게 사전 고지한 후 서비스의 전부
            또는 일부의 제공을 중단할 수 있습니다.
          </li>
          <li>
            회사는 긴급한 시스템 점검, 증설, 교체, 제반 설비 장애, 정전, 통신 두절, 이용량 폭주, 천재지변,
            국가비상사태 등 또는 운영상 상당한 이유가 있는 경우 사전 예고 없이 "서비스"의 제공을 일시적으로
            중단할 수 있습니다. 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
          </li>                                                  
        </ol>

        <h5>제13조 게시물 또는 내용물의 삭제</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 회원이 게시하거나 전달하는 서비스 내의 모든 내용물(회원 간 전달 포함)이 다음 각 호의 경우에
            해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 회사, 다른 회원 또는 제 3 자를 비방하거나 중상모략 등으로 명예를 훼손하는 경우
              </li>
              <li>
                <span>②</span> 공공질서, 미풍양속에 위반되는 경우
              </li>
              <li>
                <span>③</span> 범죄적 행위에 결부된다고 인정되는 경우
              </li>
              <li>
                <span>④</span> 회사에서 제공하는 서비스와 관련 없는 내용, 승인되지 않은 광고, 판촉물 등을 게재하는 경우
              </li>
              <li>
                <span>⑤</span> 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 경우
              </li>
              <li>
                <span>⑥</span> 기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우
              </li>                                       
            </ul>            
          </li>
          <li>
            회사는 제3자로부터 게시물이 명예훼손, 저작권 등 기타 권리 침해 등의 문제가 있으므로 게시 중단
            등의 조치를 취할 것을 요청받고, 객관적으로 게시물 상의 문제가 있다고 판단되는 경우
            게시물의 임시 게시 중단(전송 중단) 조치를 취할 수 있습니다.            
          </li>
          <li>
            게시물이 임시 게시 중단된 경우 게시물을 등록한 회원은 회사에 게시물의 재게시(전송 재개)를 요청할 수 있고,
            회사는 재게시 요청이 정당하다고 판단되는 경우 재게시 조치를 취할 수 있습니다. 단, 게시 중단 요청자와 게시물
            등록자 간의 판결, 조정, 화해, 합의 등이 이루어진 경우 회사는 그 결정에 따라 게시물의 게시 여부를 결정하게 됩니다.
          </li>        
        </ol>

        <h5>제14조 게시물의 저작권</h5>
        <ol class="list-group-numbered">
          <li>
            회사가 작성한 게시물 또는 저작물에 대한 저작권 등 일체의 지적재산권은 회사에 귀속합니다.
          </li>
          <li>
            회원이 서비스 내에 게시한 게시물(회원 간 전달 포함)의 저작권은 회원에게 귀속됩니다.
            단, 회사는 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 회원의 별도의 허락 없이 무상으로
            저작권법에 규정하는 공정한 관행에 합치되게 합리적인 범위 내에서 다음과 같이 회원이 등록한
            게시물을 사용할 수 있습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 서비스 내에서 회원 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및 저작물성을
                해치지 않는 범위 내에서의 편집 저작물 작성
              </li>
              <li>
                <span>②</span> 미디어, 통신사 등 서비스 제휴 파트너에게 회원의 게시물 내용을 제공, 전시 혹은
                홍보하게 하는 것. 단, 이 경우 회사는 별도의 동의 없이 회원의 이용자 ID 외에 회원의 개인 정보를
                제공하지 않습니다.
              </li>
            </ul>            
          </li>
          <li>
            회사는 전항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우 사전에 회원으로부터
            전화, 팩스, 전자우편 등의 방법을 이용하여 동의를 얻어야 합니다.
          </li>
          <li>
            회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 기타 권리를 침해하더라도 이에 대한 민ㆍ형사상의
            책임을 부담하지 않습니다. 만일 회원이 타인의 저작권 기타 권리를 침해하였음을 이유로 회사가 타인으로부터
            손해배상청구 등 이의 제기를 받은 경우, 회원은 회사의 면책을 위하여 노력하여야 하며, 회원은 그로 인해
            회사에 발생한 모든 손해를 부담하여야 합니다.
          </li>
          <li>
            회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로
            이용하거나 제 3 자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.
          </li>
          <li>
            회사는 회원이 이용계약을 해지하고 사이트를 탈퇴하거나 적법한 사유로 해지된 경우, 해당 회원이 게시하였던
            게시물 일체를 삭제할 수 있습니다.
          </li>                              
        </ol>

        <h5>제15조 정보의 제공 및 통지</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 "회원"이 "서비스" 이용에 필요하다고 인정되는 다양한 정보를 공지사항, 전자우편, SMS 등의 방법으로
            "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련 법에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변
            등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
          </li>
          <li>
            회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.
          </li>
          <li>
            회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의 하에 관련 법령에 따라 추가적인
            개인 정보를 수집할 수 있습니다.
          </li>                    
        </ol>

        <h5>제16조 광고 게재 및 광고주와의 거래</h5>
        <ol class="list-group-numbered">
          <li>
            회사가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고 게재를 통한 수익으로부터 나오는 바,
            회원은 서비스 이용 시 노출되는 광고 게재에 대해 동의합니다.
          </li>
          <li>
            회사는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는
            거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.
          </li>
        </ol>

        <h5>제17조 계약 변경 및 해지</h5>
        <ol class="list-group-numbered">
          <li>
            회원이 사이트 이용계약을 해지하고자 할 경우 회원은 사이트 상에서 또는 회사가 정한 별도의 이용방법으로
            회사에 해지 신청을 하여야 합니다.
          </li>
          <li>
            회사가 이용계약을 해지하고자 하는 경우 사전에 회원에게 개인정보보호정책에 따라 회원 등록을 말소한다는
            사실을 통지하고, 소명할 기회를 부여한 다음 회원 등록을 말소합니다.
          </li>
        </ol>

        <h5>제18조 서비스 이용제한</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 회원이 본 약관 제9조, 제11조 내용을 위반하거나, 다음 각 호에 해당하는 경우 사전 통지
            없이 회원의 서비스 이용을 제한할 수 있습니다.
            <ul class="sub-item">
              <li>
                <span>①</span> 회원정보에 부정한 내용을 등록하거나 타인의 이용자 ID, 비밀번호 기타 개인 정보를 도용하는 경우
              </li>
              <li>
                <span>②</span> 이용자 ID를 타인과 거래하거나 제공하는 경우
              </li>
              <li>
                <span>③</span> 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용 또는 타인의 명예나 프라이버시를 침해할 수 있는
                내용의 정보, 문장, 도형, 음향, 동영상 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 경우
              </li>
              <li>
                <span>④</span> 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 경우
              </li>
              <li>
                <span>⑤</span> 회사로부터 특별한 권리를 부여받지 아니한 채 회사의 클라이언트 프로그램을 변경하거나,
                회사의 서버를 해킹하거나, 사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 경우
              </li>
              <li>
                <span>⑥</span> 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나,
                이를 출판 및 방송 등에 사용하거나, 제 3 자에게 제공하는 경우
              </li>
              <li>
                <span>⑦</span> 회사의 운영진, 직원 또는 관계자를 사칭하거나 고의로 서비스를 방해하는 등 정상적인 서비스
                운영에 방해되는 경우
              </li>
              <li>
                <span>⑧</span> 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우
              </li>
              <li>
                <span>⑨</span> 회원 가입 후 어느 정도의 기간 동안 사용 이력이 없는 회원에 대해 사용의사를 묻는 고지를 하고,
                회사가 정한 기한 내에 답변이 없는 경우
              </li>
              <li>
                <span>⑩</span> 기타 관련 법령, 약관을 포함하여 회사가 정한 제반 규정을 위반하는 경우
              </li>                                                        
            </ul>             
          </li>
          <li>
            상기 이용 제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의
            일시정지, 초기화, 이용계약 해지 등의 조치를 취할 수 있으며, 회원은 전항의 귀책사유로 인하여 회사나 다른
            회원에게 입힌 손해를 배상할 책임이 있습니다.
          </li>
        </ol>

        <h5>제19조 손해배상</h5>
        <ol class="list-group-numbered">
          <li>
            회원이 이 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은
            회사에 발생하는 모든 손해를 배상하여야 합니다.
          </li>
          <li>
            회원이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터
            손해배상 청구 또는 소송을 비롯한 각종 이의 제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를
            면책시켜야 하며, 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
          </li>
          <li>
            회원이 이 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은
            회사에 발생하는 모든 손해를 배상하여야 합니다.
          </li>                    
        </ol>

        <h5>제20조 면책사항</h5>
        <ol class="list-group-numbered">
          <li>
            회사는 천재지변, 전쟁 및 기타 불가항력, 회사의 합리적인 통제 범위를 벗어난 사유로 인하여 서비스를
            제공할 수 없는 경우에는 그에 대한 책임이 면제됩니다.
          </li>
          <li>
            회사는 기간 통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가
            발생한 경우 책임이 면제됩니다.
          </li>
          <li>
            회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
          </li>
          <li>
            회사는 이용자의 귀책사유로 인한 서비스 이용의 장애, 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우,
            또는 회원이 신상정보 및 이메일 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
          </li>
          <li>
            회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
          </li>
          <li>
            회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않으며,
            회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
          </li>
          <li>
            회원이 서비스에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 혹은 정확성에 대하여는 해당 회원이 책임을 부담하며,
            회사는 내용의 부정확 또는 허위로 인해 회원 또는 제 3 자에게 발생한 손해에 대하여는 아무런 책임을 부담하지 않습니다.
          </li>
          <li>
            회사는 회원 상호 간 및 회원과 제 3 자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며,
            이로 인한 손해배상 책임도 없습니다.
          </li>
          <li>
            회사는 회원의 게시물 등록 전에 사전심사 하거나 상시적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가 없습니다.
          </li>
          <li>
            회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.
          </li>                                           
        </ol>

        <h5>제21조 분쟁 시 준거법과 관할법원</h5>
        <ol class="list-group-numbered">
          <li>
            회사와 회원 간에 서비스 이용으로 발생한 분쟁에 대하여는 대한민국법을 적용하며, 본 분쟁으로 인하여
            소송이 제기될 경우 회사의 본사 소재지 민사소송법상의 관할을 가지는 대한민국의 법원에 제기합니다.
          </li>
          <li>
            회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 성실히 협의합니다.
          </li>
          <li>
            회사의 정액 서비스 회원 및 기타 유료 서비스 이용 회원의 경우 당해 서비스와 관련하여서는
            회사가 별도로 정한 약관 및 정책에 따릅니다.
          </li>          
        </ol>

        <h5>부칙</h5>
        <ul>
          <li>
            (시행일) 본 약관은 2022년 7월 26일부터 시행합니다.
          </li>
        </ul>

      </div><!-- // rule-list -->
    </div>
  </div>
</template>

<script>
import location from '@/components/Location'

export default {
  name: 'Terms',
  components: {
    location
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>