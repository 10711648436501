<template>
  <div>
    <location />
    <b-form @submit="onSubmit">
      <div class="page wrap-1200 bp-w440-lt">
        <p class="info">
          환영합니다! 회원가입하신 후 다양한 X-GOLF 멤버십 서비스를 이용해
          보세요.
          <span class="required"></span>로 표시된
          <span class="text-red">필수 항목</span> 외 입력 정보는 선택
          항목입니다. 입력하신 이메일 주소는 귀하의 X-GOLF 계정으로 아이디 및
          비밀번호를 잊어버리면 해당 이메일 주소로 정보를 보내드립니다.
        </p>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">아이디</span></label
            >
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              class="btn-addon"
              name="id"
              placeholder="공백없이 영문, 숫자, '_' 만 입력 가능 3글자 이상 20글자 미만"
              id="id"
              v-model="form.id"
              required
              @keyup="availability_reset(1)"
            />
            <button
              class="btn btn-primary btn-md check"
              type="button"
              @click="availability_check(1, form.id)"
            >
              중복확인
            </button>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">비밀번호</span></label
            >
          </div>
          <div class="col-lg-9">
            <input
              type="password"
              name="password"
              id="password"
              v-model="form.password"
              required
            />
            <small>{{ formValidation.password }}</small>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">비밀번호 확인</span></label
            >
          </div>
          <div class="col-lg-9">
            <input
              type="password"
              name="password_re"
              id="password_re"
              v-model="form.password_re"
              required
            />
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">이메일</span></label
            >
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              class="btn-addon"
              placeholder="공백없이 100글자 미만"
              name="email"
              id="email"
              v-model="form.email"
              required
              @keyup="availability_reset(3)"
            />
            <button
              class="btn btn-primary btn-md check"
              type="button"
              @click="availability_check(3, form.email)"
            >
              중복확인
            </button>
            <small
              >회원가입 후 인증메일이 발송됩니다. 인증메일을 확인하신 후에
              사이트 이용이 가능합니다</small
            >
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">닉네임</span></label
            >
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              class="btn-addon"
              placeholder="공백없이 한글, 영문, 숫자만 입력 가능 2글자 이상 20글자 미만"
              name="nickname"
              id="nickname"
              v-model="form.nick"
              required
              @keyup="availability_reset(2)"
            />
            <button
              class="btn btn-primary btn-md check"
              type="button"
              @click="availability_check(2, form.nick)"
            >
              중복확인
            </button>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"><span class="required">성</span></label>
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              placeholder="공백없이 한글, 영문, 숫자만 입력 가능  20글자 미만"
              name="firstname"
              id="firstname"
              v-model="form.firstname"
            />
          </div>
        </div>
        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">이름</span></label
            >
          </div>
          <div class="col-lg-9">
            <input
              type="text"
              name="lastname"
              placeholder="공백없이 한글, 영문, 숫자만 입력 가능 20글자 미만"
              id="lastname"
              v-model="form.lastname"
            />
          </div>
        </div>
        <!-- <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">표준시간대</span></label
            >
          </div>
          <div class="col-lg-9">
            <div class="row g-0">
              <div class="col-8">
                <select
                  name="timezone"
                  id="timezone"
                  v-model="form.timezone"
                >
                  <option
                    v-for="(tz, index) in timezone_arr"
                    :value="tz"
                    :key="index + 't'"
                  >
                    UTC{{ tz >= 0 ? "+" + tz : tz }} : 00
                  </option>
                </select>
              </div>
              <div class="col-4">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="daylighttime"
                    name="daylighttime"
                    v-model="form.daylighttime"
                  />
                  <label class="form-check-label" for="daylighttime"
                    >일광절약시간</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">성별</span></label
            >
          </div>
          <div class="col-lg-9 d-table">
            <div class="d-table-cell align-middle">
              <div class="form-check d-inline-block me-3">
                <input
                  class="form-check-input"
                  type="radio"
                  value="M"
                  id="Male"
                  name="gender"
                  checked
                  v-model="form.gender"
                />
                <label class="form-check-label" for="Male">남성</label>
              </div>
              <div class="form-check d-inline-block">
                <input
                  class="form-check-input"
                  type="radio"
                  value="F"
                  id="Female"
                  name="gender"
                  v-model="form.gender"
                />
                <label class="form-check-label" for="Female">여성</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">오른/왼손잡이</span></label
            >
          </div>
          <div class="col-lg-9 d-table">
            <div class="d-table-cell align-middle">
              <div class="form-check d-inline-block me-3">
                <input
                  class="form-check-input"
                  type="radio"
                  value="R"
                  id="Right"
                  name="hand"
                  checked
                  v-model="form.hand"
                />
                <label class="form-check-label" for="Right">오른손</label>
              </div>
              <div class="form-check d-inline-block">
                <input
                  class="form-check-input"
                  type="radio"
                  value="L"
                  id="Left"
                  name="hand"
                  v-model="form.hand"
                />
                <label class="form-check-label" for="Left">왼손</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">티 높이</span></label
            >
          </div>
          <div class="col-lg-9">
            <select name="teeheight" id="teeheight" v-model="form.teeheight">
              <option
                v-for="(height, index) in teeheight_arr"
                :selected="index === 0"
                :key="index"
                :value="height"
              >
                {{ height }}
              </option>
            </select>
          </div>
        </div>

        <div class="row g-0">
          <div class="col-lg-3 bg-gray d-table">
            <label class="d-table-cell"
              ><span class="required">장비설정</span></label
            >
          </div>
          <div class="col-lg-9">
            <div class="row g-0">
              <div class="col-lg-4 set">
                <select
                  name="fieldunit"
                  id="fieldunit"
                  v-model="form.fieldunit"
                  required
                >
                  <option value="" disabled selected>페어웨이</option>
                  <option value="1">M</option>
                  <option value="2">Yard</option>
                  <option value="3">Feet</option>
                </select>
              </div>
              <div class="col-lg-4 set">
                <select
                  name="greenunit"
                  id="greenunit"
                  v-model="form.greenunit"
                  required
                >
                  <option value="" disabled selected>그린</option>
                  <option value="1">M</option>
                  <option value="2">Yard</option>
                  <option value="3">Feet</option>
                </select>
              </div>
              <div class="col-lg-4 set">
                <select
                  name="speedunit"
                  id="speedunit"
                  v-model="form.speedunit"
                  required
                >
                  <option value="" disabled selected>속도</option>
                  <option value="1">km/h</option>
                  <option value="2">mile/h</option>
                  <option value="3">m/s</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="agree_box px-2 px-lg-4">
          <div class="form-check mb-2 mb-lg-3">
            <input
              type="checkbox"
              id="privacy"
              required
              class="form-check-input"
              v-model="form.privacy"
              :checked="form.privacy === '1'"
            />
            <label class="form-check-label" for="privacy"
              >X-GOLF 온라인 서비스
              <router-link
                :to="{ name: 'Terms' }"
                class="text-decoration-underline"
                target="_blank"
                >이용약관</router-link
              >
              및
              <router-link
                :to="{ name: 'Privacy' }"
                class="text-decoration-underline"
                target="_blank"
                >개인정보처리방침</router-link
              >
              에 동의합니다.</label
            >
          </div>
          <div class="form-check">
            <input
              type="checkbox"
              id="emailagree"
              class="form-check-input"
              v-model="form.emailagree"
              :checked="form.emailagree === '1'"
            />
            <label class="form-check-label" for="emailagree"
              >X-GOLF로부터 프로모션 및 혜택에 대한 이메일을 받는 것에
              동의합니다. (이 구독은 언제든지 취소할 수 있습니다.)</label
            >
          </div>

          <div class="agree_box">
            <vue-recaptcha
              :sitekey="RECAPTCHA_SITEKEY"
              :loadRecaptchaScript="true"
              @verify="verify"
            ></vue-recaptcha>
          </div>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            type="submit"
            size="xl"
            variant="primary"
            aria-label="회원가입"
            >회원가입</b-button
          >
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import ax from "@/api/auth";
import _ from "lodash";
import location from "@/components/Location";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Signup",
  data() {
    return {
      form: {
        recaptcha_yn: "n",
        recaptcha_token: "",
        id: "",
        id_chk: "n",
        idoverlap: "0",
        password: "",
        password_re: "",
        email: "",
        email_chk: "n",
        emailoverlap: "0",
        nick: "",
        nick_chk: "n",
        nicknameoverlap: "0",
        firstname: "",
        lastname: "",
        gender: "M",
        hand: "R",
        teeheight: 0,
        fieldunit: "",
        greenunit: "",
        speedunit: "",
        emailagree: 0,
        privacy: 0,
      },
      formValidation: {
        password:
          "비밀번호는 영문, 숫자를 포함하여 최소 8자, 최대 30자 이내로 입력해주세요.",
      },
      teeheight_arr: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
      timezone_arr: [
        -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8,
        9, 10, 11,
      ],
      RECAPTCHA_SITEKEY: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    };
  },
  components: {
    location,
    VueRecaptcha,
  },
  created() {},
  methods: {
    verify(response) {
      this.form.recaptcha_token = response;
      this.form.recaptcha_yn = "y";
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.form.daylighttime) this.form.daylighttime = 1;
      else this.form.daylighttime = 0;

      if (this.form.emailagree) this.form.emailagree = 1;
      else this.form.emailagree = 0;

      if (this.form.privacy) this.form.privacy = 1;
      else this.form.privacy = 0;

      if (this.form.id === "") {
        alert("아이디를 입력해주세요.");
        return false;
      }
      if (this.form.id_chk === "n") {
        alert("아이디체크 확인 해주세요.");
        this.form.idoverlap = "0";
        return false;
      }
      this.form.idoverlap = "1";

      if (this.form.password === "") {
        alert("비밀번호를 입력하세요.");
        return false;
      }

      if (this.form.password !== this.form.password_re) {
        alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        return false;
      }

      if (this.form.email === "") {
        alert("이메일을 입력하세요.");
        return false;
      }
      if (this.form.email_chk === "n") {
        alert("이메일체크를 확인하십시오.");
        this.form.emailoverlap = "0";
        return false;
      }

      if (this.form.firstname === "") {
        alert("성을 입력하세요.");
        return false;
      }

      if (this.form.lastname === "") {
        alert("이름을 입력하세요.");
        return false;
      }

      this.form.emailoverlap = "1";

      if (this.form.nick === "") {
        alert("닉네임을 입력해주세요.");
        return false;
      }
      if (this.form.nick_chk === "n") {
        alert("닉네임체크를 확인해주세요.");
        this.form.nicknameoverlap = "0";
        return false;
      }
      this.form.nicknameoverlap = "1";

      if (this.form.recaptcha_yn === "n") {
        alert("자동등록방지를 확인하세요.");
        return false;
      }

      ax.sign_up(this.form, (flag, code, message) => {
        if (flag) {
          if (code === 200) {
            alert(message);
            this.$router.push({ name: "Login" });
            return true;
          } else {
            alert(message);
            //alert('code가 200이 아님')
            return false;
          }
        } else {
          alert(message);
          return false;
        }
      });
    },
    availability_check(type, name) {
      if (name === "") {
        // alert('데이터를 넣어주세요')
        if (type === 1) {
          alert("아이디를 입력해주세요.");
        } else if (type === 2) {
          alert("닉네임을 입력해주세요.");
        } else if (type === 3) {
          alert("이메일을 입력하세요.");
        }
        return false;
      }

      ax.availability_check(type, name, (flag, msg, code) => {
        if (flag) {
          if (code === 200) {
            if (type === 1) {
              this.form.id_chk = "y";
            } else if (type === 2) {
              this.form.nick_chk = "y";
            } else if (type === 3) {
              this.form.email_chk = "y";
            }
            alert(msg);
          }
        } else {
          alert(msg);
          return false;
        }
      });
    },
    availability_reset(type) {
      if (type === 1) {
        this.form.id_chk = "n";
      } else if (type === 2) {
        this.form.nick_chk = "n";
      } else if (type === 3) {
        this.form.email_chk = "n";
      }
    },
  },
  watch: {
    "form.password": {
      handler: function (val) {
        const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,}/; // 문자, 숫자 1개이상 포함, 8자리 이상
        if (!idReg2.test(val)) {
          this.formValidation.password =
            "비밀번호는 영문, 숫자를 포함하여 최소 8자, 최대 30자 이내로 입력해주세요.";
        } else {
          this.formValidation.password = "이 암호를 사용할 수 있습니다.";
        }
      },
    },
  },
};
</script>
